import React from 'react'
import {Box , Center , Text , Image , Link, Button , useToast} from '@chakra-ui/react'
import logo from '../../assets/metacratch.webp'
import Third from '../Home/sections/Third/Third'
import {motion} from 'framer-motion'
import Chart from 'react-apexcharts'
import character from '../../assets/character.webp'
import token from '../../assets/token.webp'

function Token() {

  const toast = useToast()

  const values = [
    {
    title : "Token Type",
    text: 'TNT-20',
    num: ""
    },
    {
      title : "$CRTC Max Supply",
      text: '400 ',
      num: 'Million'
      },

]
const Animate = {
  hidden : {
    scale: 0.5,
    opacity:0
  },
  visible : custom => ({
    scale: 1,
    opacity:1,
    transition : {delay : custom * 0.2, type: 'spring'},
  }),
}

const AnimateSection = {
  hidden : {
    x: -100,
    opacity:0
  },
  visible : custom => ({
    x: 0,
    opacity:1,
    transition : {delay : custom * 0.2, type: 'spring'},
  }),
}

const AnimateImage = {
  hidden : {
    y: 100,
    opacity:0
  },
  visible : custom => ({
    y: 0,
    opacity:1,
    transition : {delay : custom * 0.2, type: 'spring'},
  }),
}

const team = ["Team" , "Ecosystem" , "Community" , "Sale"]
const budget = [11 , 47 , 26 , 16]


  return (
    <>
    <Box bg="linear-gradient(to bottom, #030a12, #060e16, #08111a, #0a141e, #0b1722, #0c1825, #0d1928, #0f1a2b, #121a2d, #151a2f, #191a31, #1e1932);" fontFamily="heading">
      <Box  height={["50vh","50vh","70vh","70vh"]}>
      
      
        <Center width="100%" height="100%" >
            <Box width="80%" d="flex"   height="100%">
                <Box width={["100%","100%","60%","50%"]} height="100%">
                  <Center width="100%" height="100%">
                  <motion.div
                      initial={{ opacity: 0, x: -100 }}
                      animate={{ opacity: 1, x: 0 }}
                      exit={{ opacity: 0, x: 100 }}
                      transition={{duration: 0.4, ease: 'easeInOut'}}
                      >
                        
                    
                      <Text fontSize={["1.8rem","2rem","2.5rem","2.5rem"]} fontWeight="bold" pt={5} lineHeight="2.6rem" color="brand.900">
                      Virtual World led by $CRTC
                      </Text>
                      <Text as="p" color="brand.100" fontSize={["1.1rem ","1.1rem","1.2rem","1.2rem"]} mt={3} width={["100%","100%","85%","85%"]}>
                      $CRTC is a digital currency created on the Theta Blockchain exclusively for the exchange and transfer of value in Cratch, MetaCratch and MobiCratch.
                      </Text>
                      <motion.div
                                        initial="hidden"
                                        whileInView="visible"
                                        custom={1}
                                        variants={AnimateSection}
                                        
                                        >
                      <Button onClick={() => {
                        toast({
                          title: `Coming soon! Stay tuned!`,
                          position: "top",
                          isClosable: true,
                          status: 'info', 
                        })
                      }}  _hover={{
                        bg: '#0088FF'
                      }} _active={{
                        bg: '#0088FF'
                      }} bg="#0088FF" mt={10} width="9.375rem" height="3.125rem" fontWeight="bold" borderRadius="10px" fontSize={["1.1rem","1.2rem","1.3rem","1.3rem"]} color="brand.900">
                          Buy Now
                      </Button></motion.div>
                      </motion.div>
                      </Center>
                </Box>
                <Box width={["40%","50%"]} display={['none' , 'none' , 'block' , 'block']}>
                  <Center width="100%" height="100%">
                      <Image src={token} width="100%" alt="image" /> 
                  </Center>
                      
                </Box>
            </Box>
        </Center>
       
      </Box>
      
      
        <Box height="auto" bg="rgb(4, 11, 17)" pl={[0,5,20,20,40]} pr={[0,5,20,20,40]} pb={10}>
          
            <Box height="100%" width="100%">
              <Box d={["grid","grid","flex","flex"]}>
                    <Box mt={20} pl={2} width={["100%","100%","50%","60%"]}>
                      <Text as="h1" fontSize={["1.7rem","1.9rem","2.4rem","2.4rem"]} pl={[10,5,0,0]}  width="90%" lineHeight={["2.5rem","2.5rem","2.5rem","2.5rem"]} fontWeight="bold" color="brand.900" letterSpacing="normal">
                      CRTC is a digital token for a digital economy
                      </Text>
                      <Text mt={5} as="p" fontSize="1.2rem" width="90%" pl={[10,5,0,0]} color="brand.100" letterSpacing="normal">
                      $CRTC is a digital currency created on the Theta Blockchain for buying and selling assets, lands, studios or NFTs.
                      </Text>


                      <Box width="100%" d="flex" pt={12} pl={[10,5,5,5]}>
                {values.map((data) => (
                    
                      <Box width="100%">
                        <Text  as="p" fontSize="1rem" width="90%"  color="brand.100" letterSpacing="normal" >
                        {data.title}
                        </Text>
                        <Box d="flex" m="0" p="0"><Text mt={2} as="h1" fontSize={["1.7rem","1.9rem","2.2rem","2.4rem"]} width="100%" lineHeight="2.3rem" fontWeight="bold" color="brand.900" letterSpacing="normal">
                        {data.text} <span style={{fontSize: '1.2rem' , color: 'rgba(255, 255, 255, 0.6)'}}>{data.num}</span>
                        </Text>
                        
                      </Box>
                        
                      </Box>
                      
                

                ))}

                    
                </Box>
                    </Box>
                    <Box mt={20} pl={[0,0,5,5]} width={["100%","100%","50%","40%"]} height="100%">
                    <motion.div
                        initial="hidden"
                        whileInView="visible"
                        variants={Animate}
                        custom={1}
                        
                        >
                        <Chart type="donut" 
                            series={budget} 
                            options={{
                              fill: {
                                type: 'gradient',
                              },
                              legend: {
                                show: true,
                                showForSingleSeries: false,
                                showForNullSeries: true,
                                showForZeroSeries: true,
                                position: 'top',
                                horizontalAlign: 'center', 
                                floating: false,
                                fontSize: '14px',
                                fontFamily: 'Helvetica, Arial',
                                fontWeight: 400,
                                formatter: undefined,
                                inverseOrder: false,
                                width: '100%',
                                height: undefined,
                                tooltipHoverFormatter: undefined,
                                custoplegendItems: [],
                                offsetX: 0,
                                offsetY: 0,
                                labels: {
                                    colors: "white",
                                    useSeriesColors: false
                                },

                                itemMargin: {
                                    horizontal: 6,
                                    vertical: 2
                                },
                                onItemClick: {
                                    toggleDataSeries: true
                                },
                                onItemHover: {
                                    highlightDataSeries: true
                                },
                            },
            

                                tooltip: {
                                    y: {
                                        formatter:(val) => {
                                            return `${val.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')} %`
                                        }
                                    }
                                },
                                labels:team,
                                title: {
                            style: {
                                color: 'white',
                                fontWeight : 'bold'
                            }
                            }
                            }}
                           
				
                            />
        </motion.div>
                    </Box>
              </Box>
              
              
                    
            </Box>
            
        </Box>
        
        <Box height="auto" bgColor="rgb(4, 11, 17)" pb={28}>
            <Center>
            <Box width="80%" borderTop="1px dashed rgba(255,255,255,.2)"></Box>
            </Center>

            <Box height="100%" mt={20} d={["grid","grid","flex","flex"]} pl={[0,5,20,20,40]} pr={[0,5,20,20,40]} >
                  <Box className="leftOne" width={["100%","100%","50%","50%"]} pl={[10 , 10 , 5 , 5]} pb={[10,10,0,0]}>
                    <Text as="h2" fontSize={["1.8rem","2.2rem","3rem","3rem"]} lineHeight="3rem" fontWeight="bold" color="brand.900">
                    Buy, Hold $CRTC and Earn Rewards
                    </Text>
                    <Text mt={6} as="p" fontSize={["1.1rem ","1.1rem","1.2rem","1.2rem"]} width="90%"  color="brand.100" lineHeight="2rem" fontWeight="light">
                    When you buy $CRTC tokens, you potentially profit from future price appreciation. Beyond price gains, you will be able to stake $CRTC tokens to start earning special lands, studios and NFTs.
                      </Text>
                      <motion.div
                                        initial="hidden"
                                        whileInView="visible"
                                        custom={1}
                                        variants={AnimateSection}
                                        
                                        >
                      <Button  onClick={() => {
                        toast({
                          title: `Coming soon! Stay tuned!`,
                          position: "top",
                          isClosable: true,
                          status: 'info', 
                          
                        })
                      }}  _hover={{
                      bg: '#0088FF'
                        }} _active={{
                      bg: '#0088FF'
                        }} bg="#0088FF" mt={10} width="16rem" height="50px" fontWeight="bold" borderRadius="10px" fontSize={["1.1rem ","1.1rem","1.2rem","1.2rem"]} color="brand.900">
                        Buy $CRTC
                        </Button>
                        </motion.div>

                          
                          <motion.div
                                        initial="hidden"
                                        whileInView="visible"
                                        custom={1.5}
                                        variants={AnimateSection}
                                        
                                        >
                        <Link href='https://explorer.thetatoken.org/account/0xec4a227cf9f4aa489469ab2afdf584fd5b06ec56' isExternal><Button _hover={{
                      bg: 'transparent'
                        }} border="solid 1px white" bg="transparent" mt={5} width="16rem" height="50px" fontWeight="bold" borderRadius="10px" fontSize={["1.1rem ","1.1rem","1.2rem","1.2rem"]} color="brand.900">
                        View on Explorer
                        </Button></Link></motion.div>

                  </Box>

                  <Box className="rightOne" width={["100%","100%","50%","50%"]}  pl={[10 , 10 , 5 , 5]} >
                      <Text  as="p" fontSize="1.3rem" width="90%"  color="brand.900" lineHeight="2rem" fontWeight="bold">
                          Accelerated and Sustainable Adoption
                      </Text>
                      <Text mt="5px" as="p" fontSize="1.0rem" width="90%"  color="brand.100" lineHeight="1.6rem" fontWeight="light">
                          Cratch is revolutionizing live streaming, video sharing, and the digital content economy with practical applications of blockchain. We are building best-in-class decentralized solutions for rapidly-growing industries to drive the sustainable adoption of the Cratch brand across our core verticals.
                      </Text>
                      <Text mt={5} as="p" fontSize="1.3rem" width="90%"  color="brand.900" lineHeight="2rem" fontWeight="bold">
                          Token-Utility-Market Fit
                      </Text>
                      <Text mt="5px" as="p" fontSize="1.0rem" width="95%"  color="brand.100" lineHeight="1.6rem" fontWeight="light">
                          $CRTC is used in Cratch's ecosystem for buying and selling assets, lands, studios or NFTs, earning staking rewards on MetaCratch & MobiCratch, incentivizing views and engagement on the platform.
                      </Text>
                      <Text mt={5} as="p" fontSize="1.3rem" width="90%"  color="brand.900" lineHeight="2rem" fontWeight="bold">
                          Vast & Rich Ecosystem
                      </Text>
                      <Text mt="5px" as="p" fontSize="1.0rem" width="95%"  color="brand.100" lineHeight="1.6rem" fontWeight="light">
                      The Cratch ecosystem includes MetaCratch, MobiCratch and NFTs. Cratch will buy-back and burn $CRTC to reduce supply. Traders and investors buy $CRTC to profit from the market dynamics.
                      </Text>
                  </Box>

            </Box>
        </Box>
        
        <Box>
            <Center>
                
            </Center>

                      <Third 
                      heading="Want to know what other users think about $CRTC?" 
                      bg="radial-gradient(circle at 50% -100%, rgb(4, 11, 17), #0E0C1A)" 
                      cardColor="rgba(255,255,255,.01)"
                      showButton
                      paragraph="Join one of the fastest-growing community of Metaverse lovers, crypto enthusiasts, live streamers and content creators. We are intentional about building an international community that is currently supported in 5 languages with more to come."
                      />
        </Box>
        <Box  height="auto" bgColor="rgb(4, 11, 17)">
        <Center>
                
            </Center>
        <Center bg="#0A0914" width="100%">
            <Box width={["85%","80%","80%","80%"]} d={["grid","grid","flex","flex"]}  mt={20}  height="100%" pb={10} justifyContent="center" alignContent="center" alignItems="center">
            <Box width={["100%","100%","50%","50%"]} height="100%" d={['block' , "block" , "none" , "none"]} >
                    <Image src={character} />
                </Box>
                <Box width={["100%","100%","50%","50%"]} >
                  <Image src={logo} width="13rem" alt="logo" mt={5}/>
                    <Text fontSize={["1.9rem","2.1rem","2.1rem","2.5rem"]} fontWeight="bold" mt={5} lineHeight="2.6rem" color="brand.900">
                    Experience Digital & Social Media in a Futuristic Way                    </Text>
                    <Text as="p" color="brand.100" fontSize={["1rem","1.1rem","1.2rem","1.2rem"]} mt={5} width={["100%","100%","95%","95%"]}>
                    Explore lands and studios owned by users to receive an incredible experience. Join the community and connect with your favorite streamers and influencers in the Metaverse.
                    </Text>
                    <motion.div whileTap={{scale : 0.9}}>
                    <Link href='https://meta.cratch.io/' isExternal>
                      <Button _hover={{
                        bg: '#0088FF'
                      }} _active={{
                        bg: '#0088FF'
                      }} bg="#0088FF" mt="50px" width="12.5rem" height="50px" fontWeight="bold" borderRadius="10px" fontSize={["1.1rem","1.2rem","1.3rem","1.3rem"]} color="brand.900">
                          Visit MetaCratch
                      </Button>
                    </Link>
                    </motion.div>
                </Box>
                <Box width={["100%","100%","50%","50%"]}  height="100%" pl={5} d={['none' , "none" , "block" , "block"]}>
                <motion.div
                          initial="hidden"
                          whileInView="visible"
                          variants={AnimateImage}
                          custom={1}
                          >
                    <Image src={character} />
                    </motion.div>
                </Box>
            </Box>
        </Center>
       
      </Box>
       </Box>

    </>
  )
}

export default Token